import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserUserModel} from '../models/UserUserModel';

@Injectable()
export class UsersService {
  domain_zone = 'ru';

  constructor(private http: HttpClient) {
    if (window.location.hostname.includes('.kz')) {
      this.domain_zone = 'kz';
    }
  }

  getUserByLogin(user: UserUserModel) {
    return this.http.post<string>(`https://rmas.teyca.${this.domain_zone}/auth/login1`, user);
  }

  getUserOut() {
    return this.http.get<Response>(`https://rmas.teyca.${this.domain_zone}/auth/logout`);
  }

  getlocale() {
    return this.http.get<string>(`https://rmas.teyca.${this.domain_zone}/locale`);
  }

  checkUser() {
    return this.http.get<string>(`https://rmas.teyca.${this.domain_zone}/checkuser`);
  }
}
