import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {UsersService} from './shared/services/users.service';
import {LoaderService} from './shared/services/loader.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthService} from './shared/services/auth.service';
import {CardsService} from './shared/services/cards.service';
import {LoaderInterceptor} from './shared/interceptors/loader.interceptor';
import {AuthGuard} from './shared/services/auth.guard';
import {AuthModule} from './auth/auth.module';
import {SharedModule} from './shared/shared.module';
import {NotFoundComponent} from './not-found/not-found.component';
import {AuthInterceptor} from './shared/interceptors/auth.interceptor';
import {SystemInterceptor} from './shared/interceptors/system.interceptor';
import {MatSnackBarModule} from '@angular/material';
import {CookieService} from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  exports: [
    NotFoundComponent
  ],
  providers: [UsersService, AuthService, CardsService, LoaderService, CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }, AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor , multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: SystemInterceptor , multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
