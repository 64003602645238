import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {LoaderComponent} from './loader/loader.component';
import {MatProgressSpinnerModule} from '@angular/material';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [ReactiveFormsModule, FormsModule, MatProgressSpinnerModule, CommonModule],
  exports: [ReactiveFormsModule, FormsModule, LoaderComponent],
  declarations: [LoaderComponent]
})

export class SharedModule {
}
