import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UsersService} from '../../shared/services/users.service';
import {UserModel} from '../../shared/models/user.model';
import {MessageModels} from '../../shared/models/message.models';
import {AuthService} from '../../shared/services/auth.service';
import {Meta, Title} from '@angular/platform-browser';
import {UserUserModel} from '../../shared/models/UserUserModel';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  message: MessageModels;
  user: UserModel;
  user1: UserUserModel;
  columnsRMK: string;

  constructor(private userService: UsersService, private authService: AuthService, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta) {
    title.setTitle('Вход в РМA');
    meta.addTags([
      {name: 'keywords', content: 'логин, вход, авторизация, система'},
      {name: 'description', content: 'Страница для входа в систему'}
    ]);
    this.columnsRMK = 'template,cdf_fio,cdf_phone,cdf_discount';
  }

  ngOnInit() {
    /*this.userService.checkUser().subscribe((res: string) => {
              this.columnsRMK = 'user;
          },
          ErrorEvent => {},
          () => {
          window.sessionStorage.setItem('columnsRMK', this.columnsRMK);
          this.authService.login();
          this.router.navigate(['/system', 'cards']);
         });
         */

    this.message = new MessageModels('danger', '');
    this.form = new FormGroup({
      login: new FormControl(null, [Validators.required, Validators.maxLength(256)]),
      password: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(256)])
    });

    this.route.queryParams.subscribe((params: Params) => {
      if (params.accessDenied) {
        this.MessageShow('Для работы с системой вам необходимо войти.', 'warning');
      }
    });

    this.route.queryParams.subscribe((params: Params) => {
      if (params.goOut) {
        this.MessageShow('В системе может работать только 1 менеджер одновременно.', 'warning');
      }
    });
    this.route.queryParams.subscribe((params: Params) => {
      if (params.error) {
        this.MessageShow('Технические неполадки. Попробуйте позже.', 'danger');
      }
    });
  }

  private MessageShow(text: string, type: string = 'danger') {
    this.message = new MessageModels(type, text);
    window.setTimeout(() => {
      this.message.text = '';
    }, 5000);
  }

  onSubmit() {
    this.user = new UserModel(this.form.value.login, this.form.value.password);
    this.user1 = new UserUserModel(this.user);
    this.userService.getUserByLogin(this.user1).subscribe((user: string) => {
      this.columnsRMK = user;
    }, (error: HttpErrorResponse) => {
      if (error.status === 500) {
        this.MessageShow('Технические неполадки. Попробуйте позже.');
      } else if (error.status === 0) {
        this.MessageShow('Технические неполадки. Попробуйте позже.');
      } else {
        this.MessageShow(error.error);
      }
    }, () => {
      window.localStorage.setItem('columnsRMK', this.columnsRMK);
      this.authService.login();
      this.router.navigate(['/system', 'cards']);
    });
  }
}
