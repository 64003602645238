import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserModel} from '../models/user.model';
import {AuthService} from './auth.service';
import {ReadModels} from '../models/read.models';
import {WriteModels} from '../models/write.models';
import {CreateModels} from '../models/create.models';
import {CardModels} from '../models/Card.models';
import {MatSnackBar} from '@angular/material';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: ''
  })
};

@Injectable()
export class CardsService {
  user: UserModel;
  domain_zone = 'ru';

  constructor(private http: HttpClient, private authService: AuthService, private _snackBar: MatSnackBar) {
    if (window.location.hostname.includes('.kz')) {
      this.domain_zone = 'kz';
    }
  }

  openDangerSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'danger-snack'
    });
  }

  openWarningrSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'warning-snack'
    });
  }

  openSuccessSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'success-snack'
    });
  }

  getKey(): Observable<string> {
    this.user = JSON.parse(window.sessionStorage.getItem('user'));
    return this.http.get<string>(`https://cards.teyca.${this.domain_zone}/${this.user.username.toString()}/authorization`, httpOptions);
  }

  getReadParams(): Observable<ReadModels> {
    return this.http.get<ReadModels>(`https://rmas.teyca.${this.domain_zone}/findpass`);
  }

  getWriteParams(): Observable<WriteModels> {
    return this.http.get<WriteModels>(`https://rmas.teyca.${this.domain_zone}/writepass`);
  }

  getCreateParams(): Observable<CreateModels> {
    return this.http.get<CreateModels>(`https://rmas.teyca.${this.domain_zone}/createpass`);
  }

  getAllTemplatesNames(): Observable<string[]> {
    return this.http.get<string[]>(`https://rmas.teyca.${this.domain_zone}/templatesnames`);
  }

  getAllLoyaltyLevels(): Observable<string[]> {
    return this.http.get<string[]>(`https://rmas.teyca.${this.domain_zone}/loyaltylevelnames`);
  }

  postFindPass(formvalue: FormData) {
    return this.http.post<CardModels[]>(`https://rmas.teyca.${this.domain_zone}/findpass1`, formvalue);
  }

  postCreatePass(card: CardModels) {
    return this.http.post<CardModels[]>(`https://rmas.teyca.${this.domain_zone}/createpass`, card);
  }

  postUpdatePass(card: CardModels) {
    return this.http.post<CardModels[]>(`https://rmas.teyca.${this.domain_zone}/updatepass`, card);
  }

  postDeletePass(card: CardModels) {
    return this.http.post<Response>(`https://rmas.teyca.${this.domain_zone}/deletepass`, card);
  }

  postSendSMS(card: CardModels) {
    return this.http.post<Response>(`https://rmas.teyca.${this.domain_zone}/sendsms`, card);
  }

  postVerTelNumber(tel: FormData) {
    return this.http.post<string>(`https://rmas.teyca.${this.domain_zone}/smsverification`, tel);
  }

  postSendEmail(card: CardModels) {
    return this.http.post<Response>(`https://rmas.teyca.${this.domain_zone}/sendemail`, card);
  }
}
